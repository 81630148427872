import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {Router} from "@angular/router";
import {QueryService} from "../../query/query";

import {QueryBuilder} from "../../query/query-builder";
import { DeviceDetectorService } from 'ngx-device-detector';
import {iGpts, iUser} from "../../models/interfaces";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService) { }

  public status = 1;
  public page = 1;
  public perPage = 100;

  public isMobile:boolean = false;

  public users:iUser[] = [];
  // public statuses:any[] = [];
  public usersData:any = {};

  public statuses = {
    '-1': '❌',
    '0': '⌛',
    '1': '✔'
  };

  public gptsPage: number = 1;

  public gpts:any = {
    data: []
  };

  public currentUser:iUser = null;
  public lastCopied = 0;

  public reloadTimeout =  5000;
  public timer = null;
  public deletionAllow = false;

  public newUser: iUser = null;

  ngOnInit(): void {
    if(!this.account.isAuth()){
      this.router.navigateByUrl('/login');
    }
    this.isMobile = this.deviceService.isMobile();
    if(this.isMobile){
      this.status = -2;
    }
    else {
      this.loadUser();
    }

  }

  selectUser(user:iUser){
    this.currentUser = user;
    this.newUser = JSON.parse(JSON.stringify(user));
    this.loadGpts(user);

    this.dropTimer();
  }

  loadGpts(user:iUser, page = 1){
    this.gptsPage = page;
    if(this.gptsPage < 1){
      this.gptsPage = 1;
    }
    this.gpts = {data: []};
    const qb = new QueryBuilder()
        .page(this.gptsPage)
        .orderBy('gpt_id', 'desc')
        .per_page(100)
        .where('user_id', '=', user.user_id)
    ;
    this.query.all('gpt', qb).then(res => this.gpts = res);
  }

  dropTimer(){
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
    this.timer = setInterval(() => this.reloadGpts(this.currentUser), this.reloadTimeout > 200 ? this.reloadTimeout : 200);
  }

  removeTimer(){
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  ngOnDestroy(){
    this.removeTimer();
  }

  reloadGpts(user:iUser){
    if(this.gptsPage > 1){
      return;
    }
    const qb = new QueryBuilder()
        .page(this.gptsPage)
        .orderBy('gpt_id', 'desc')
        .per_page(100)
        .where('user_id', '=', user.user_id)
        .with(['gptRequest'])
    ;
    if(this.gpts.data.length){
      qb.where('created_at', '>=', this.gpts.data[0].created_at);
    }
    this.query.all('gpt', qb).then(res => {
      if(res.data[0] && this.gpts.data[0] && res.data[0]?.gpt_id == this.gpts.data[0]?.gpt_id){
        res.data[0].show = this.gpts.data[0].show;
        this.gpts.data[0] = res.data[0];
        res.data.shift();
      }
      this.gpts.data = [...res.data, ...this.gpts.data];
    });
  }

  deleteUser(){
    this.query.del('user', this.currentUser.user_id).then(res => {
      this.loadUser();
      this.currentUser = null;
    });
  }


  loadUser(status = 1, page = 1, callback = null){
    this.status = status;
    this.page = page;
    if(this.page < 1){
      this.page = 1;
    }

    const qb = new QueryBuilder()
        .orderBy('user_id', 'desc')
        .page(this.page)
        .per_page(this.perPage)
        ;
    // if(this.status > -1){
    //   qb.where('status', '=', this.status);
    // }

    this.query.all('user', qb).then(res => {
      this.users = res.data;
      this.usersData = res;
      if(callback){
        callback();
      }
    });
  }

  updateData(){
    this.newUser.paid_per = new Date(this.newUser.paid_per).toISOString();
    this.query.update('user', this.currentUser.user_id, this.newUser).then(res => {
      this.currentUser = res;
      this.currentUser.password = '';
      this.newUser = JSON.parse(JSON.stringify(res));
      this.loadUser(this.status, this.page);
    });
  }

}
