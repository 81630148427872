import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {Router} from "@angular/router";
import {QueryService} from "../../query/query";

import {QueryBuilder} from "../../query/query-builder";
import { DeviceDetectorService } from 'ngx-device-detector';
import {iGpts, iGptsVariant} from "../../models/interfaces";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService) { }

  public status = 1;
  public page = 1;
  public perPage = 10;

  public isMobile:boolean = false;

  public statuses:any[] = [];
  public gptsData:any = {};

  public variants:any = {
    data: []
  };

  public gpts:iGptsVariant[] = [];
  public currentGpts:iGptsVariant = null;
  public lastCopied = 0;

  public newGpt: iGptsVariant = null;

  ngOnInit(): void {
    if(!this.account.isAuth()){
      this.router.navigateByUrl('/login');
    }
    this.isMobile = this.deviceService.isMobile();
    if(this.isMobile){
      this.status = -2;
    }
    else {
      this.loadGptsVariants();
    }

  }

  selectGpt(gpt:iGptsVariant){
    this.currentGpts = gpt;
    this.newGpt = JSON.parse(JSON.stringify(gpt));
  }

  loadGptsVariants(){
    const qb = new QueryBuilder().per_page(99999).orderBy('status', 'desc').orderBy('gpts_variant_id', 'desc');
    this.query.all('gptVariant', qb).then(res => {
      this.gptsData = res;
      this.gpts = res.data;
    });
  }

  updateVariant(variant){
    this.query.update('gptVariant', variant.gpts_variant_id, variant).then(res => {
      this.loadGptsVariants();
    });
  }

  createVariant(){
    this.query.create('gptVariant', {status: 0}).then(res => {
      this.loadGptsVariants();
    });
  }

  deleteVariant(variant){
    this.query.del('gptVariant', variant.gpts_variant_id).then(res => {
      this.loadGptsVariants();
    });
  }

  loadGpts(status = 1, page = 1, callback = null){
    this.status = status;
    this.page = page;
    if(this.page < 1){
      this.page = 1;
    }

    const qb = new QueryBuilder().per_page(this.perPage).page(this.page)
        .orderBy('status', 'desc').orderBy('gpts_variant_id', 'desc');
    this.query.all('gptVariant', qb).then(res => {
      this.gptsData = res;
      this.gpts = res.data;
    });
  }

  updateData(){
    this.query.update('gptVariant', this.currentGpts.gpts_variant_id, this.newGpt).then(res => {
      this.currentGpts = res;
    });
  }

}
