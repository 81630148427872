import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {Router} from "@angular/router";
import {QueryService} from "../../query/query";

import {QueryBuilder} from "../../query/query-builder";
import { DeviceDetectorService } from 'ngx-device-detector';
import {iGpts} from "../../models/interfaces";

@Component({
  selector: 'app-gpts',
  templateUrl: './gpts.component.html',
  styleUrls: ['./gpts.component.scss']
})
export class GptsComponent implements OnInit {

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService) { }

  public STATUS_DONE = 1;
  public STATUS_WAIT = 0;
  public STATUS_WRONG = -1;
  public STATUS_ALL = -2;
  public STATUS_SHOW_PAGE = -3;

  public statuses = {
    '0': '⌛',
    '1': '✔',
    '-1': '❌',
  };


  public status = -2;
  public page = 1;
  public perPage = 100;

  public isMobile:boolean = false;

  public gpts:iGpts[] = [];
  // public statuses:any[] = [];
  public gptsData:any = {};

  public variants:any = {
    data: []
  };

  public shows = {};

  public currentGpts:iGpts = null;
  public lastCopied = 0;

  public newGpt: iGpts = null;

  ngOnInit(): void {
    if(!this.account.isAuth()){
      this.router.navigateByUrl('/login');
    }
    this.isMobile = this.deviceService.isMobile();
    if(this.isMobile){
      this.status = this.STATUS_SHOW_PAGE;
    }
    else {
      this.loadGpts();
    }
    this.loadGptsVariants();
  }

  selectGpt(gpt:iGpts){
    this.currentGpts = gpt;
    this.newGpt = JSON.parse(JSON.stringify(gpt));
  }

  loadGptsVariants(){
    const qb = new QueryBuilder().per_page(99999).orderBy('status', 'desc').orderBy('gpts_variant_id', 'desc');
    this.query.all('gptVariant', qb).then(res => {
      this.variants = res;
    });
  }

  updateVariant(variant){
    this.query.update('gptVariant', variant.gpts_variant_id, variant).then(res => {
      this.loadGptsVariants();
    });
  }

  createVariant(){
    this.query.create('gptVariant', {status: 0}).then(res => {
      this.loadGptsVariants();
    });
  }

  deleteVariant(variant){
    this.query.del('gptVariant', variant.gpts_variant_id).then(res => {
      this.loadGptsVariants();
    });
  }

  loadGpts(status = this.STATUS_ALL, page = 1, callback = null){
    this.status = status;
    this.page = page;
    if(this.page < 1){
      this.page = 1;
    }

    const qb = new QueryBuilder()
        .orderBy('gpt_id', 'desc')
        .page(this.page)
        .per_page(this.perPage)
        .with([
          'user',
          'gptsVariant',
          'gptRequest'
        ]);
    if(this.status >= this.STATUS_WRONG){
      qb.where('status', '=', this.status);
    }

    this.query.all('gpt', qb).then(res => {
      this.gpts = res.data;
      this.gptsData = res;
      if(callback){
        callback();
      }
    });
  }

  updateData(){
    this.query.update('gpt', this.currentGpts.gpt_id, this.newGpt).then(res => {
      this.currentGpts = res;
    });
  }

}
