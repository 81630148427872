import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {QueryService} from "../query/query";
import {HttpClientModule} from "@angular/common/http";
import {AccountService} from "../query/account";
import { LoginRegisterComponent } from './login-register/login-register.component';
import {FormsModule} from "@angular/forms";
import { GptsComponent } from './gpts/gpts.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
// import {DpDatePickerModule} from "ng2-date-picker";
import {
  OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl,
  OwlDateTimeModule,
  OwlNativeDateTimeModule
} from '@danielmoncada/angular-datetime-picker';
import {BrowserAnimationsModule, NoopAnimationsModule} from "@angular/platform-browser/animations";
import {UserComponent} from "./user/user.component";
import {SettingsComponent} from "./settings/settings.component";


export const options: Partial<null|IConfig> | (() => Partial<IConfig>) = null;

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

export class DefaultIntl extends OwlDateTimeIntl {
  /** A label for the up second button (used by screen readers).  */
  override upSecondLabel = '+ секунда'

  /** A label for the down second button (used by screen readers).  */
  override downSecondLabel = '- секунда'

  /** A label for the up minute button (used by screen readers).  */
  override upMinuteLabel= '+ минута'

  /** A label for the down minute button (used by screen readers).  */
  override downMinuteLabel= '- минута'

  /** A label for the up hour button (used by screen readers).  */
  override upHourLabel= '+ час'

  /** A label for the down hour button (used by screen readers).  */
  override downHourLabel= '- час'

  /** A label for the previous month button (used by screen readers). */
  override prevMonthLabel= 'Пред месяц'

  /** A label for the next month button (used by screen readers). */
  override nextMonthLabel= 'След месяц'

  /** A label for the previous year button (used by screen readers). */
  override prevYearLabel= 'Пред год'

  /** A label for the next year button (used by screen readers). */
  override nextYearLabel= 'След год'

  /** A label for the previous multi-year button (used by screen readers). */
  override prevMultiYearLabel= 'Пред 21 год'

  /** A label for the next multi-year button (used by screen readers). */
  override nextMultiYearLabel= 'След 21 год'

  /** A label for the 'switch to month view' button (used by screen readers). */
  override switchToMonthViewLabel= 'Месяцы'

  /** A label for the 'switch to year view' button (used by screen readers). */
  override switchToMultiYearViewLabel= 'Выбрать месяц и год'

  /** A label for the cancel button */
  override cancelBtnLabel= 'Отмена'

  /** A label for the set button */
  override setBtnLabel= 'Установить'

  /** A label for the range 'from' in picker info */
  override rangeFromLabel= 'От'

  /** A label for the range 'to' in picker info */
  override rangeToLabel= 'До'

  /** A label for the hour12 button (AM) */
  override hour12AMLabel= 'AM'

  /** A label for the hour12 button (PM) */
  override hour12PMLabel: 'PM'
};

@NgModule({
  declarations: [
    AppComponent,
    LoginRegisterComponent,
    GptsComponent,
      UserComponent,
      SettingsComponent
  ],
  imports: [
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    // DpDatePickerModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,

  ],
  providers: [QueryService,
    AccountService,
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru-RU'},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS},
    {provide: OwlDateTimeIntl, useClass: DefaultIntl}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
