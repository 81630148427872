
<div class="columns" id="mail-app">
    <aside class="column is-2 aside hero is-fullheight" *ngIf="!isMobile || (isMobile && status == -2)">
        <div>
            <div class="main">
                <a class="item" href="/"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Запросы</span></a>
                <a class="item active" (click)="loadUser(-1, 1);currentUser = null;users=[];removeTimer();"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Юзеры</span></a>
                <a class="item" href="/settings"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Настройки</span></a>
            </div>
        </div>
    </aside>
    <div class="column messages hero is-fullheight" id="message-feed" *ngIf="!currentUser && (!isMobile || (isMobile && status > -2))">
        <div class="action-buttons">
            <div class="control is-grouped pg">
                <button class="button" *ngIf="isMobile" (click)="status = -2;">Вернуться назад</button>
                <div class="title">Список пользователей (страница {{ page }} из {{ usersData?.last_page }})</div>
                <a class="button " [class.is-link]="!isMobile" (click)="loadUser(this.status, page-1)"><i class="fa fa-chevron-left"></i></a>
                <a class="button " [class.is-link]="!isMobile" (click)="loadUser(this.status, page+1)"><i class="fa fa-chevron-right"></i></a>
            </div>
        </div>

        <div class="inbox-messages" id="inbox-messages">
            <div *ngFor="let user of users" class="card clickable" [class.active]="currentUser?.user_id == user.user_id" (click)="selectUser(user)">
                <div class="card-content">
                    <div class="msg-header">

                        <span class="msg-timestamp">Создан:{{ user.created_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</span>
                        <span class="msg-timestamp">Оплачен до:{{ user.paid_per | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</span>
<!--                        <span class="msg-attachment"><i class="fa fa-paperclip"></i></span>-->
                    </div>
                    <div class="msg-subject">
                        <span class="msg-subject"><strong id="fake-subject-1">ID: {{ user.user_id }} Name: {{ user.firstName }} {{ user.lastName }}</strong></span>
                    </div>
                    <div class="msg-snippet">
                        <p id="fake-snippet-1">{{ user.email }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="column is-fullheight" [class.message]="!isMobile" *ngIf="currentUser" id="message-pane">

        <div class=""  [class.box]="!isMobile">
            <div class="top">
                <div class="address">
                    <div class="name">
                        <div class="field is-grouped is-grouped-multiline" >
                            <p class="control">
                                <a class="button" (click)="currentUser = null;removeTimer();">Вернуться назад</a>
                            </p>
                        </div>
                    </div>

                </div>
                <hr>
                <div class="content">

                    <table class="table is-bordered">
                        <tr>
                            <td>Номер</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" type="text" [(ngModel)]="newUser.user_id">
                                    </div>
                                    <div class="control" *ngIf="currentUser.user_id != newUser.user_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Емэйл</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.email">
                                    </div>
                                    <div class="control" *ngIf="currentUser.email != newUser.email" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Имя</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.firstName">
                                    </div>
                                    <div class="control" *ngIf="currentUser.firstName != newUser.firstName" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Фамилия</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.lastName">
                                    </div>
                                    <div class="control" *ngIf="currentUser.lastName != newUser.lastName" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Пароль (всегда скрыт)</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.password">
                                    </div>
                                    <div class="control" *ngIf="currentUser.password != newUser.password" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Оплачено до</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <span class="clickable" [owlDateTimeTrigger]="dt1">{{ newUser.paid_per | date:'dd.MM.YYYY HH:mm':'GMT+3'}}</span>
                                        <input [owlDateTime]="dt1" style="visibility:hidden;width: 1px;height: 1px;"  [(ngModel)]="newUser.paid_per">
                                        <owl-date-time #dt1></owl-date-time>
                                    </div>
                                    <div class="control" *ngIf="currentUser.paid_per != newUser.paid_per" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Триальные токены</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.gpt_tokens_trial">
                                    </div>
                                    <div class="control" *ngIf="currentUser.gpt_tokens_trial != newUser.gpt_tokens_trial" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Токены</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.gpt_tokens">
                                    </div>
                                    <div class="control" *ngIf="currentUser.gpt_tokens != newUser.gpt_tokens" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Роль</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <label class="radio">
                                            <input type="radio" [value]="0" name="newUserRole" [(ngModel)]="newUser.role">
                                            Пользователь
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="1" name="newUserRole" [(ngModel)]="newUser.role">
                                            Админ
                                        </label>
                                    </div>

                                    <div class="control" *ngIf="currentUser.role != newUser.role" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Дата создания</td>
                            <td>{{ currentUser.created_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>
                        <tr>
                            <td>Дата изменения</td>
                            <td>{{ currentUser.updated_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>
                        <tr>
                            <td (click)="deletionAllow = true;">Удалить?</td>
                            <td>
                                <a *ngIf="deletionAllow" (click)="deleteUser(); deletionAllow = false;">Удалить</a>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">

                                <nav class="is-small block is-2" role="navigation" aria-label="pagination">
                                    <a class="pagination-previous" (click)="loadGpts(currentUser, gptsPage-1)"><-</a>
                                    Страница {{ gptsPage }} из {{ gpts.last_page || 0 }}
                                    <a  class="pagination-next" (click)="loadGpts(currentUser, gptsPage+1)">-></a>
                                </nav>
                                <nav class="is-small block is-2" role="navigation" aria-label="pagination">
                                    <label for="reloadTimeout">Частота обновления в мс</label>
                                    <input id="reloadTimeout" type="text" class="input" [(ngModel)]="reloadTimeout" (change)="dropTimer()">
                                </nav>

                                <article class="message clickable" style="padding: 0;" *ngFor="let gpt of gpts.data">
                                    <div class="message-header" (click)="gpt.show = !gpt.show">
                                        <p>(Статус: {{ statuses[gpt.status] }} за {{ gpt.attempts }} запрос) {{ gpt.created_at | date:'dd.MM.YYYY HH:mm:ss' }}</p>
                                    </div>
                                    <div class="message-body" *ngIf="gpt.show">
                                        Запрос: {{ gpt.message }}
                                        <hr>
                                        Ответ: {{ gpt.gpt_request.response[gpt.gpt_request.response.length - 1].content }}
                                    </div>
                                </article>
                            </td>
                        </tr>


                    </table>

                </div>
            </div>
        </div>
    </div>
</div>