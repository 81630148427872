import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginRegisterComponent} from "./login-register/login-register.component";
import {GptsComponent} from "./gpts/gpts.component";
import {UserComponent} from "./user/user.component";
import {SettingsComponent} from "./settings/settings.component";

const routes: Routes = [

  {path: 'login', component: LoginRegisterComponent},
  {path: 'users', component: UserComponent},
  {path: 'settings', component: SettingsComponent},
  {path: '', component: GptsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
