
<div class="columns" id="mail-app">
    <aside class="column is-2 aside hero is-fullheight" *ngIf="!isMobile || (isMobile && status == STATUS_SHOW_PAGE)">
        <div>
            <div class="main">
                <a class="item active" (click)="loadGpts(STATUS_ALL, 1);currentGpts = null;gpts=[];"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Запросы</span></a>
                <a class="item" href="/users"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Юзеры</span></a>
                <a class="item" href="/settings"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Настройки</span></a>

            </div>
        </div>
    </aside>
    <div class="column messages hero is-fullheight" id="message-feed" *ngIf="!currentGpts && (!isMobile || (isMobile && status > STATUS_ALL))">
        <div class="action-buttons">
            <div class="control is-grouped pg">
                <button class="button" *ngIf="isMobile" (click)="status = STATUS_SHOW_PAGE;">Вернуться назад</button>
                <div class="title">Список заявок (страница {{ page }} из {{ gptsData?.last_page }})</div>
                <a class="button " [class.is-link]="!isMobile" (click)="loadGpts(this.status, page-1)"><i class="fa fa-chevron-left"></i></a>
                <a class="button " [class.is-link]="!isMobile" (click)="loadGpts(this.status, page+1)"><i class="fa fa-chevron-right"></i></a>
            </div>
        </div>

        <div class="inbox-messages" id="inbox-messages">
            <div *ngFor="let gpt of gpts" class="card" [class.active]="currentGpts?.gpt_id == gpt.gpt_id">
                <div class="card-content">
                    <div class="msg-header">
                        <small>
                            <span>{{ [
                                gpt.user_id,
                                gpt.user?.firstName + ' ' + gpt.user?.lastName,
                                gpt.user?.email,
                            ].join(' / ') }}</span>
                            <span> ({{ statuses[gpt.status] }})</span><br>
                            <a (click)="shows[gpt.gpt_id] = !shows[gpt.gpt_id];">{{ gpt.created_at | date:'dd.MM.YYYY HH:mm:ss' }} -> {{ gpt.updated_at | date:'dd.MM.YYYY HH:mm:ss' }}</a><br>
                        </small>
                    </div>

                    <div class="msg-snippet clickable"  (click)="selectGpt(gpt)">
                        <p id="fake-subject-1">{{ gpt.message }}</p>
                        <br>
                        <div class="block pl-5" *ngIf="gpt.gpt_request">
                            <small>
                                Assistant ({{ statuses[gpt.status] }})
                                <span *ngIf="gpt.gpt_tokens_request || gpt.gpt_tokens_response">Токены: {{ gpt.gpt_tokens_request }} / {{ gpt.gpt_tokens_response }}</span>
                            </small>
                            <br><br>
                            <p *ngFor="let response of gpt.gpt_request.response">{{ response.content }}</p>
                        </div>
                    </div>


                    <div class="msg-snippet" *ngIf="shows[gpt.gpt_id] && gpt.gpt_request">
                        <table class="table" style="width: 100%; white-space: pre-line;">
                            <tr><td colspan="2">Запрос в GPT</td></tr>
                            <tr *ngFor="let req of gpt.gpt_request?.request">
                                <td>{{ req.role }}</td>
                                <td style="font-size: small">{{ req.content }}</td>
                            </tr>
                            <tr *ngFor="let res of gpt.gpt_request?.response">
                                <td style="color:red;">assistant</td>
                                <td style="font-size: small">{{ res.content }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="column hero is-fullheight" [class.message]="!isMobile" *ngIf="currentGpts" id="message-pane">

        <div class=""  [class.box]="!isMobile">
            <div class="top">
                <div class="address">
                    <div class="name">
                        <div class="field is-grouped is-grouped-multiline" >
                            <p class="control">
                                <a class="button" (click)="currentGpts = null;">Вернуться назад</a>
                            </p>
                        </div>
                    </div>
                    <div class="name">{{ currentGpts.gpt_id }}</div>
                </div>
                <hr>
                <div class="content">
                    <table class="table is-bordered">
                        <tr>
                            <td>Номер</td>
                            <td>
                                <div class="field">
                                    <div class="control">
                                        <input class="input" type="text" [(ngModel)]="newGpt.gpt_id" readonly style="cursor: default;">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.gpt_id != newGpt.gpt_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Инструкция</td>
                            <td>
                                <div class="field">
                                    <textarea class="textarea" readonly style="cursor: default;"
                                    >{{ newGpt.gpt_request?.request[0]?.content }}</textarea>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Запрос юзера</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <textarea class="textarea" [(ngModel)]="newGpt.message"></textarea>
                                    </div>
                                    <div class="control" *ngIf="currentGpts.message != newGpt.message" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Ответ</td>
                            <td>
                                <div class="field" >
                                    <textarea class="textarea" readonly style="cursor: default;"
                                    >{{ newGpt.gpt_request?.response[0]?.content }}</textarea>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Респонс сервера (JSON)</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <textarea class="textarea" [(ngModel)]="newGpt.response"></textarea>
                                    </div>
                                    <div class="control" *ngIf="currentGpts.response != newGpt.response" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Кол-во попыток запроса</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newGpt.attempts">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.attempts != newGpt.attempts" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>ID юзера</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input type="text" class="input" [(ngModel)]="newGpt.user_id">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.user_id != newGpt.user_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Статус</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <label class="radio">
                                            <input type="radio" [value]="1" name="status" [(ngModel)]="newGpt.status">
                                            {{ statuses['1'] }}
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="0" name="status" [(ngModel)]="newGpt.status">
                                            {{ statuses['0'] }}
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="-1" name="status" [(ngModel)]="newGpt.status">
                                            {{ statuses['-1'] }}
                                        </label>
                                    </div>

                                    <div class="control" *ngIf="currentGpts.status != newGpt.status" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Дата создания</td>
                            <td>{{ currentGpts.created_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>
                        <tr>
                            <td>Дата изменения</td>
                            <td>{{ currentGpts.updated_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>


                    </table>

                </div>
            </div>
        </div>
    </div>
</div>